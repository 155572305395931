import React, { memo, useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@mui/styles';
import useNotificationLoading from '@utils/useNotificationLoading';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';

// import { currencyFormat } from '@utils/Tools';
// import { getUrl, loginGame, postUrl } from '@utils/ApiAction';
// import { storePlayerId } from '@actions';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import { FreeMode } from 'swiper/modules';

import { Container, InputLabel, FormControl, Grid, Input, InputAdornment, IconButton, Hidden, Box, Link } from '@mui/material';

import { MdSquare, MdOutlineMail, MdArrowOutward } from "react-icons/md";
import { IoLogoDiscord, IoLogoTwitter } from "react-icons/io5";
import { FaTelegram } from "react-icons/fa6";
import { FaReddit } from "react-icons/fa";
import { FiArrowRight, FiCheck } from "react-icons/fi";
import { HiMiniArrowUpCircle } from "react-icons/hi2";

import 'swiper/css/bundle';
// import { storePaths } from '@utils/Tools';


const Home = memo(() => {
    const theme = useTheme();
    const styles = useStyles();
    const { t, i18n } = useTranslation();
    const isMountedRef = useRef(null);
    let navigate = useNavigate();
    let location = useLocation();
    const dispatch = useDispatch();
    const { addAlert, setLoading } = useNotificationLoading();
    const [walletTooltip, setWalletTooltip] = useState({});

    //Subscribe Email
    const [doneSubscribe, setDoneSubscribe] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [hasStartedTyping, setHasStartedTyping] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(false);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const handleInputChange = (event) => {
        const emailInput = event.target.value;
        setInputValue(emailInput);
        if (emailInput.length > 0) {
            setHasStartedTyping(true);
            setIsValidEmail(emailRegex.test(emailInput));
        }
        else {
            setHasStartedTyping(false);
            setIsValidEmail(false);            
        }
    };

// setIsValidEmail(emailRegex.test(emailInput));

    // const [data, setData] = useState({});

    // const WalletInfo = (props) => {
    //     let { decimal, balance, locked_balance } = props.info;
    //     let withdrawableBalance = parseFloat(balance) - parseFloat(locked_balance || 0);
    //     balance = currencyFormat(parseFloat(balance), decimal);
    //     locked_balance = currencyFormat(parseFloat(locked_balance || 0), decimal);
    //     withdrawableBalance = currencyFormat(withdrawableBalance, decimal);

    //     return (
    //         <div className="dis-flex flex-col-l-m" style={{ flexDirection: 'column', gap: 8 }}>
    //             <Typography>{`${t('home.currentBalance')}: $ ${balance}`}</Typography>
    //             <Typography>{`${t('home.withdrawable')}: $ ${withdrawableBalance}`}</Typography>
    //             <Typography>{`${t('home.locked')}: $ ${locked_balance}`}</Typography>
    //         </div>
    //     )
    // }

    // const showWalletTooltip = (walletId) => {
    //     setWalletTooltip({ ...walletTooltip, [walletId]: true })
    // }

    // const hideWalletTooltip = (walletId) => {
    //     setWalletTooltip({ ...walletTooltip, [walletId]: false })
    // }

    return (
        <div>

            <div style={{ backgroundImage: 'url("/images/public/header.png")', backgroundSize: 'cover', backgroundPosition: 'top center', backgroundRepeat: 'no-repeat' }}>
                <Container className='p-tb-50'>
                    <div className="w-full flex-sb-m flex-responsive p-lr-30">
                        <div>
                            <div className='inter-bold p-b-40 lh-16 txt-header'>
                                <p>Power Neural</p>
                                <p>and its ecosystem</p>
                                <p>with <span className='text-gradient inter-bold'>NEU</span></p>

                            </div>
                            <Hidden only={'xs'}>
                                <Link to="https://pancakeswap.finance/" component={RouterLink} underline='none' target='blank'>
                                    <div className='p-all-15 clwhite txt-center header-button'>
                                        <p>Get NEU</p>
                                    </div>
                                </Link>
                            </Hidden>
                        </div>
                        <div style={{ width: 350, maxWidth: '100%' }}>
                            <img src='/images/public/header-img.png' alt='header' />
                        </div>
                        <Hidden smUp>
                            <Link to="https://pancakeswap.finance/" component={RouterLink} underline='none' target='blank' className='w-full'>
                                <div className='p-all-15 clwhite txt-center header-button'>
                                    <p>Get NEU</p>
                                </div>
                            </Link>
                        </Hidden>
                    </div>
                </Container>
            </div>
            <Container className='p-tb-80'>
                <div className='flex-stretch'>
                    <div className='p-b-20 box-stretch-responsive'>
                        <div className='p-all-40 clwhite h-full' style={{ background: '#343FF5', borderRadius: 24 }}>
                            <p className='fs-24 p-b-20'>What is NEU</p>
                            <p className='fs-18 lh-16'>NEU is the native utility token that powers Neural and its rapidly growing ecosystem.</p>
                            <div className='p-t-100' style={{ width: 200, maxWidth: '100%', margin: '0 auto' }}>
                                <img src='/images/public/coin.png' alt="coin" />
                            </div>
                        </div>
                    </div>
                    <div className='p-b-20 box-stretch-responsive'>
                        <div className='h-full flex-col flex-sb-m'>
                            <Hidden only='xs'>
                                <div className='p-lr-15 m-b-20 pos-relative p-t-25 w-full' style={{ border: '1px solid #E9E4FF', borderRadius: 24, minHeight: 'calc(50% - 10px)', backgroundImage: 'url("/images/public/coin-design2.png")', backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom center', backgroundSize: 'cover' }}>
                                    <p className='fs-21 inter-bold lh-13' style={{ color: '#343FF5' }}>What is NEU<br />used for?</p>
                                </div>
                            </Hidden>
                            <Hidden smUp>
                                <div className='flex-sb-m w-full p-tb-20'>
                                    <p className='fs-28 inter-bold lh-13 p-l-20' style={{ color: '#343FF5' }}>What is NEU<br />used for?</p>
                                    <div style={{ width: '45%' }}><img src='/images/public/coin-design1.png' alt="coin" /></div>
                                </div>
                            </Hidden>
                            <div className='p-lr-36 p-tb-40 cl-dark w-full' style={{ background: '#E1EEFF', borderRadius: 24, minHeight: 'calc(50% - 10px)' }}>
                                <div className='icon-box'>
                                    <img src='/images/public/icon1.png' alt='icon' />
                                </div>
                                <div className='p-t-36'>
                                    <p className='fs-21 inter-bold'>GameFi</p>
                                    <p className='fs-14 inter-normal p-t-25 lh-14'>NEU allows players to exchange in-game resources based on their holdings without depleting tokens, ensuring a fair and balanced experience.</p>
                                </div>
                                <div className='p-t-36 translateX'>
                                    <Link to='https://neuralsport.app/' component={RouterLink} underline='none' target='blank'>
                                        <p className='inter-normal fs-14 flex-m' style={{ color: '#343FF5' }}>Visit GameFi <FiArrowRight className='m-l-10' /></p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='p-b-20 box-stretch-responsive'>
                        <div className='h-full flex-col flex-sb-m'>
                            <div className='p-lr-36 p-tb-40 cl-dark m-b-20' style={{ background: '#E1EEFF', borderRadius: 24, minHeight: 'calc(50% -10px)' }}>
                                <div className='icon-box'>
                                    <img src='/images/public/icon2.png' alt='icon' />
                                </div>
                                <div className='p-t-36'>
                                    <p className='fs-21 inter-bold'>PoS Security</p>
                                    <p className='fs-14 inter-normal p-t-25 lh-14'>NEU is used to secure the Neural blockchain using a proof-of-stake (PoS) mechanism. Validators and delegators can both participate in staking to earn rewards.</p>
                                </div>
                                <div className='p-t-36' style={{ opacity: 0.3 }}>
                                    <p className='inter-normal fs-14 flex-m' style={{ color: '#343FF5' }}>Start Staking <FiArrowRight className='m-l-10' /></p>
                                </div>
                            </div>
                            <div className='p-lr-36 p-tb-40 cl-dark' style={{ background: '#E1EEFF', borderRadius: 24, minHeight: 'calc(50% - 20px)' }}>
                                <div className='icon-box'>
                                    <img src='/images/public/icon2.png' alt='icon' />
                                </div>
                                <div className='p-t-26'>
                                    <p className='fs-21 inter-bold'>Governance</p>
                                    <p className='fs-14 inter-normal p-t-25 lh-14'>Neural is governed by its community with NEU. Through governance, the Neural community passes proposals concerning chain upgrades, protocol integrations.</p>
                                </div>
                                <div className='p-t-36' style={{ opacity: 0.3 }}>
                                    <p className='inter-normal fs-14 flex-m' style={{ color: '#343FF5' }}>Governance <FiArrowRight className='m-l-10' /></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>

            <Container className='p-b-48'>
                <div style={{ borderRadius: 24, background: 'radial-gradient(195.69% 195.69% at 50% 0.03%, #FFF 0%, #EAEBFD 46.08%, #9299FF 100%)' }}>
                    <div className='getneu-box'>
                        <div className='txt-center inter-normal p-b-56' style={{ width: 600, margin: '0 auto', maxWidth: '80%' }}>
                            <p className='txt-getneu txt-center cl-dark inter-bold p-b-36'><b>Get NEU</b></p>
                            <p className='inter-normal lh-18 fs-18'>Participate in governing, staking and interacting with Neural through NEU. Get NEU now on PancakeSwap. More listings coming soon...</p>
                        </div>
                        <Link to="https://pancakeswap.finance/" component={RouterLink} underline="none" target='blank'>
                            <div className='flex-c-m p-tb-15 p-lr-15 bgwhite pancake-button'>
                                <div style={{ width: 30, lineHeight: 0 }}>
                                    <img src='/images/public/bunny.png' alt='bunny' />
                                </div>
                                <p className='p-l-15 cl-dark' style={{ width: 'fit-content' }}>PancakeSwap</p>
                            </div>
                        </Link>
                    </div>
                </div>
            </Container>

            <div className='tokenomics-bkg'>
                <Container>
                    <div className='tokenomics-box'>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={6}>
                                <p style={{ fontSize: '2.4rem' }} className=' cl-dark inter-semibold'>NEU tokenomics</p>
                                <div style={{ color: '#343FF5' }}>
                                    <p className='p-t-20'>Total supply: 1,000,000,000 NEU</p>
                                    <p className='p-tb-20'>Circulating supply: 130,000,000 NEU (13%)</p>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <p className='fs-18 inter-normal cl-dark lh-18'>The total supply of NEU tokens is 1 billion and will be gradually released in line with a sensible vesting schedule. The token unlock schedule (vesting period) differs depending on the allocation type.</p>
                            </Grid>
                        </Grid>

                        <div className='w-full flex-c-m flex-responsive p-t-48'>
                            <div style={{ width: 250, maxWidth: '100%' }}>
                                <img src='/images/public/data.png' alt="data" />
                            </div>
                            <div style={{ width: 80, height: 80 }}></div>
                            <div className='fs-16 cl-dark'>
                                <div className='flex-m p-t-10'>
                                    <div className='square-list' style={{ background: '#383FE1' }}></div>
                                    <p className='p-l-20 inter-normal flex-m'>Emission 37.5%</p>
                                </div>
                                <div className='flex-m p-t-10'>
                                    <div className='square-list' style={{ background: '#4D6BE3' }}></div>
                                    <p className='p-l-20 inter-normal flex-m'>Research and Ecosystem 19%</p>
                                </div>
                                <div className='flex-m p-t-10'>
                                    <div className='square-list' style={{ background: '#5B87E6' }}></div>
                                    <p className='p-l-20 inter-normal flex-m'>Seed Investors 10%</p>
                                </div>
                                <div className='flex-m p-t-10'>
                                    <div className='square-list' style={{ background: '#6CA8E8' }}></div>
                                    <p className='p-l-20 inter-normal flex-m'>Initial Core Contributors 11.5%</p>
                                </div>
                                <div className='flex-m p-t-10'>
                                    <div className='square-list' style={{ background: '#79C0EA' }}></div>
                                    <p className='p-l-20 inter-normal flex-m'>Exchange Allocation 10%</p>
                                </div>
                                <div className='flex-m p-t-10'>
                                    <div className='square-list' style={{ background: '#82D3EC' }}></div>
                                    <p className='p-l-20 inter-normal flex-m'>Gaming Rewards 12%</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>

            <Container style={{ padding: '80px 30px' }}>
                <div>
                    <p className='inter-normal fs-30 lh-13'>Sign up for exclusive news</p>
                </div>
                <Hidden smUp>
                    <div className='p-t-30'></div>
                </Hidden>
                <div className='p-t-20 p-b-80 w-full flex-sb-m flex-responsive'>
                    <div className='w-full smUp-max50'>
                        {!doneSubscribe && (
                            <>
                                <FormControl sx={{ m: 1, width: 500, maxWidth: '100%' }} variant="standard">
                                    <InputLabel htmlFor="standard-adornment-password">Email Address</InputLabel>
                                    <Input
                                        value={inputValue}
                                        onChange={handleInputChange}
                                        type="email"
                                        // onChange={handleEmailChange}
                                        // onChange={(e) => setInputValue(e.target.value)}
                                        // type="text"
                                        endAdornment={
                                            <InputAdornment position="end">
                                                {isValidEmail ?
                                                    <IconButton onClick={() => setDoneSubscribe(true)} style={{ background: '#3A63F3', width: 23, height: 23, padding: 3 }}>
                                                        <MdArrowOutward style={{ color: '#fff' }} />
                                                    </IconButton> :
                                                    <IconButton style={{ background: '#c7c7c7', width: 23, height: 23, padding: 3 }}>
                                                        <MdArrowOutward style={{ color: '#fff' }} />
                                                    </IconButton>
                                                }

                                            </InputAdornment>
                                        }
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <IconButton>
                                                    <MdOutlineMail style={{ color: '#3A63F3' }} />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                {hasStartedTyping && (
                                    <div className='inter-nomral txt-right fs-12' style={{ color: `${isValidEmail ? '#39a36c' : '#e14747'}`, width: 500, maxWidth: '100%' }}>
                                        Email is {isValidEmail ? 'valid' : 'invalid'}
                                    </div>
                                )}
                            </>
                        )
                        }
                        {doneSubscribe &&
                            <div className='flex-m'>
                                <div className='flex-c-m' style={{ width: 25, height: 25, background: 'rgba(74, 186, 92, 0.20)', borderRadius: 48 }}>
                                    <div className='clwhite flex-c-m p-all-2' style={{ background: '#4aba5c', borderRadius: 48, width: 18, height: 18 }}>
                                        <FiCheck />
                                    </div>
                                </div>
                                <p className='inter-normal fs-12 p-l-10'><span style={{ color: '#4aba5c' }}>{inputValue} </span> has successfully subscribed!</p>
                            </div>
                        }
                    </div>

                    <Hidden smUp>
                        <div style={{ paddingTop: 50 }}></div>
                    </Hidden>
                    <Box className='flex-m w-full smUp-max50 socialmedia-position'>
                        {/* <Link to="https://x.com/theneuralcoin" component={RouterLink}>
                            <div className='social-box translateY'>
                                <IoLogoDiscord className='cl-dark' />
                            </div>
                        </Link> */}
                        <Link to="https://x.com/theneuralcoin" component={RouterLink}>
                            <div className='social-box translateY '>
                                <IoLogoTwitter className='cl-dark' />
                            </div>
                        </Link>
                        {/* <Link to="https://x.com/theneuralcoin" component={RouterLink}>
                            <div className='social-box translateY'>
                                <FaTelegram className='cl-dark' />
                            </div>
                        </Link> */}
                        <Link to="https://www.reddit.com/user/theneuralcoin/" component={RouterLink}>
                            <div className='social-box translateY '>
                                <FaReddit className='cl-dark' />
                            </div>
                        </Link>
                    </Box>
                </div>
                <div style={{ width: 160 }}>
                    <img src='/images/public/logo.png' alt="logo" />
                </div>
                <div style={{ borderTop: '1px solid #E5E7EB' }} className='m-tb-25' />
                <p className='fs-11 inter-normal' style={{ color: '#4E5068', opacity: 0.4 }}>Copyright© 2024 AI Neural. All rights reserved.</p>
            </Container>

            {/* wallet */}
            {/* <div style={{display:'none'}}>
            <Swiper
                slidesPerView={_.size([1,2]) > 1 ? 2 : 1}
                spaceBetween={16}
                freeMode={true}
                modules={[FreeMode]}
                style={{ width: "100%" }}
            >
                {_.map([{id:1, name:"name1"},{id:2, name:"name2"}], _wallet => {
                    return (
                        <SwiperSlide key={_wallet.id}>
                            <div className={styles.walletBoxStyle}>
                                <div className="dis-flex flex-t flex-l" style={{ flexDirection: 'column' }} >
                                    <div className="flex-c-m" style={{ gap: 8, flexDirection: 'row' }}>
                                        <Typography sx={{ color: '#101828', fontWeight: 'bold' }} variant="subtitle2" >WalletName</Typography>
                                        <ClickAwayListener onClickAway={() => hideWalletTooltip(_wallet.id)}>
                                            <Tooltip
                                                title={<WalletInfo info={_wallet} />}
                                                open={walletTooltip[_wallet.id] || false}
                                                onClose={() => hideWalletTooltip(_wallet.id)}
                                            >
                                                <div onClick={() => showWalletTooltip(_wallet.id)}>
                                                    <FiEye style={{ width: 14, height: 14 }} className={styles.iconHoverStyle} />
                                                </div>
                                            </Tooltip>
                                        </ClickAwayListener>
                                    </div>
                                    <Typography variant="body2" sx={{ color: '#667085' }}>{t('home.currentBalance')}</Typography>
                                </div>
                                <div>
                                    <Typography color="primary" variant="h6" sx={{ fontWeight: 'bold', color:'#0CC0DF' }} >$ {currencyFormat(123, 2)}</Typography>
                                </div>
                            </div>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
            </div> */}
        </div>
    )
});

const useStyles = makeStyles((theme) => ({
    iconHoverStyle: {
        color: '#667085',
        '&:hover': {
            color: theme.palette.primary.main,
        }
    },
    iconStyle: {
        color: '#FFFFFF',
    },
    walletBoxStyle: {
        flexDirection: 'column',
        borderRadius: 18,
        backdropFilter: 'blur(40px)',
        backgroundColor: '#F2F4F7',
        // width: '50%',
        gap: 16,
        padding: '21px 57px 21px 15px',
        display: 'flex',
        [theme.breakpoints.up('xs')]: {
            padding: 15,
        },
        [theme.breakpoints.up('sm')]: {
            padding: 15,
        },
    },
}));

export default Home;